<template>
  <!--el-tabs v-model="selectedTab">
    <el-tab-pane
      name="commonChart"
      label="常用圖表"
      :lazy="true"
    >
      <instrument-form form-type="commonChart" />
    </el-tab-pane>
    <el-tab-pane
      name="stormChart"
      label="暴雨圖表"
      :lazy="true"
    >
      <instrument-form form-type="stormChart" />
    </el-tab-pane>
  </el-tabs-->
  <div v-loading="loading">
    <el-form label-position="top">
      <el-form-item label="報表格式">
        <el-select
          v-model="selectedTemplate"
          filterable
          @change="changeTitleSetting"
        >
          <el-option
            v-for="report in reportTemplate"
            :key="report.id"
            :label="report.name"
            :value="report.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="報表時間">
        <el-date-picker
          v-model="download_time"
          type="date"
          format="yyyy/MM/dd"
          placeholder="選擇日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="報表標題" v-if="isTitle">
        <el-input v-model="title" />
      </el-form-item>
    </el-form>
    
    
    <el-row>
      <el-button
        @click="downloadReport"
      >
        生成該月報表
      </el-button>
    </el-row>
  </div>
</template>

<script>
import moment from 'moment';
//import CommonChart from '@/components/report/CommonChart';
//import StormChart from '@/components/report/StormChart';
export default {
  //components: {
  //  CommonChart,
  //  StormChart,
  //},
  computed: {
    selectedProject() {
      if(this.project) return this.project;
      return this.$store.getters['project/selectedProject'];
    },
    isTitle(){
      if(!this.reportTemplate || this.reportTemplate.length == 0) return false;
      const report = this.reportTemplate.find(ele=>ele.name === this.selectedTemplate);

      if(!report) return false;

      return report.units.find(ele=>ele.report_type === 'title');
    },
  },
  data() {
    return {
      loading: false,
      download_time: moment().toISOString(),
      reportTemplate: [],
      selectedTemplate: '',
      title: '',
    };
  },
  mounted(){
    this.getReportTemplate();
  },
  methods: {
    async getReportTemplate(){
      this.loading = true;
      this.$axios({
        method: 'get',
        apiName: `project/ReportTemplate/${this.selectedProject._id}`,
      }).then(res => {
        this.reportTemplate = res.data;
        this.loading = false;
      }).catch(e=>{
        this.$notifyError(e);
        this.loading = false;
      });
    },
    changeTitleSetting(){
      const report = this.reportTemplate.find(ele=>ele.name === this.selectedTemplate);

      this.title = report.units.find(ele=>ele.report_type === 'title').typesetting.text;
    },
    async downloadReport(){
      this.loading = true;
      console.log(this.download_time);
      if(this.selectedTemplate === ''){
        this.loading = false;
        return;
      }
      this.$axios({
        method: 'post',
        apiName: 'project/report',
        data: {
          project_id: this.selectedProject._id,
          report_name: this.selectedTemplate,//高捷報表
          download_time: this.download_time,
          title: this.title,
        },
        responseType: 'blob', // 確保回應是二進位流形式
      })
        .then(res => {
          // 建立 Blob 物件
          const blob = new Blob([res.data], { type: 'application/pdf' });

          // 生成一個可供下載的 URL
          const url = URL.createObjectURL(blob);

          // 建立一個隱藏的 a 標籤
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `LUR28隧道北洞口邊坡滑動自動監測歷時曲線圖-${moment().utc(true).format('YYYY/MM/DD HH:mm')}.pdf`); // 設定下載檔名

          // 自動觸發點擊事件來下載文件
          document.body.appendChild(link);
          link.click();

          // 釋放 URL 物件
          URL.revokeObjectURL(url);
          this.loading = false;
        })
        .catch(e=>{
          this.$notifyError(e);
          this.loading = false;
        });
    },
  },
};
</script>
